import asyncAppend from '@elements/async-append';
import {onFind} from "@elements/init-modules-in-scope";
import {hasClass, removeClass, append, addClass, findIn, closest, findAllIn, on} from "@elements/dom-utils";
import {setCartCount} from "./load-cart";
import axios from "axios";
import Collapse from 'bootstrap/js/dist/collapse';
import * as formValidation from '@elements/form-validation';

const defaultSelectors = {
    base: '.js-b2b-checkout-form',
    result: '.js-b2b-checkout-form__result',
    item: '.js-b2b-checkout-form__item',
    itemLoading: '.js-b2b-checkout-form__item-loading',
    itemResult: '.js-b2b-checkout-form__item-result',
    itemSubmit: '.js-b2b-checkout-form__item-submit',
    input: '.js-b2b-checkout-form__item-input',
    notifications: '.js-b2b-checkout-form__item-notifications',
};

export function init(selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        let submitButton = findIn('.js-b2b-checkout-form__submit', baseElement);
        baseElement.action = baseElement.dataset.action || "";
        let items = findAllIn(selectors.item, baseElement);
        if(items.length > 0) {
            addClass('btn-light-disabled', submitButton);
        }

        on('click', function (evt) {
            //check if all js-b2b-checkout-form__item has class is-valid

            let validItems = findAllIn(selectors.item + '.is-valid', baseElement);

            if (items.length > validItems.length) {
                evt.preventDefault();
                // get items without is-valid
                let invalidItems = items.filter(item => !hasClass('is-valid', item));
                invalidItems.forEach(function (item) {
                    addClass('is-not-valid', item);
                    removeClass('is-valid', item);
                    let collapseElement = findIn('.js-b2b-checkout-form__item-collapse', item);
                    let bsCollapse = new Collapse(collapseElement, {
                        toggle: false
                    })
                    bsCollapse.show();
                });

                formValidation.getApi(baseElement).then((formApi) => {
                    let formInstance = formApi.getFormInstance();
                    formInstance.validate();
                });
            } else {
                formValidation.getApi(baseElement).then((formApi) => {
                    let formInstance = formApi.getFormInstance();
                    formInstance.validate();
                });
                baseElement.submit();
            }
        }, submitButton);
    });
    onFind(selectors.itemSubmit, function (itemSubmitElement) {

        on('click', function (evt) {
            let form = closest(selectors.base, evt.target);
            let item = closest(selectors.item, evt.target);
            let itemAction = item.dataset.action || "";
            let itemResult = findIn(selectors.itemResult, item);
            let itemLoading = findIn(selectors.itemLoading, item);
            let itemFormData = new FormData();
            let itemNotifications = findIn(selectors.notifications, item);
            let inputs = findAllIn(selectors.input, item);
            let validItem = [];


            formValidation.getApi(form).then(async (formApi) => {
                let formInstance = formApi.getFormInstance();

                const validateItem = async () => {
                    let fields = formInstance?.getFields();
                    let data = await Promise.all(inputs.map(async (input) => {
                        let field = fields[input.name];
                        if (field) {
                            let valid = await formInstance.validateField(input.name);
                            return valid;
                        }
                    }))
                    return !data.includes("Invalid");
                }

                await validateItem().then(function (validation) {
                    if (validation) {
                        inputs.forEach(function (item) {
                            itemFormData.append(item.getAttribute('name'), item.value);
                        })

                        let pendingRequest;
                        pendingRequest = axios({
                            method: 'POST',
                            params: Object.fromEntries(itemFormData.entries()),
                            url: itemAction
                        });

                        asyncAppend({
                            target: itemResult,
                            loading: itemLoading,
                        }, pendingRequest.then(function (x) {
                            if (x.data.validated) {
                                removeClass('is-not-valid', item);
                                addClass('is-valid', item);
                                let collapseElement = findIn('.js-b2b-checkout-form__item-collapse', item);
                                let bsCollapse = new Collapse(collapseElement, {
                                    toggle: false
                                })
                                bsCollapse.hide();
                            } else {
                                removeClass('is-valid', item);
                                addClass('is-not-valid', item);
                            }
                            let notification = typeof x.data.notification === 'string'
                                ? {default: x.data.notification}
                                : x.data.notification;

                            itemNotifications.innerHTML = notification.default;

                            let base = closest(selectors.base, item);
                            let validItems = findAllIn(selectors.item + '.is-valid', base);
                            let submitButton = findIn('.js-b2b-checkout-form__submit', base);

                            let allItems = findAllIn(selectors.item, base);
                            if (allItems.length === validItems.length) {
                                removeClass('btn-light-disabled', submitButton);
                            } else {
                                addClass('btn-light-disabled', submitButton);
                            }
                            return x.data;
                        }));
                    }
                });
            });
        }, itemSubmitElement);
    });

}

