import IMask from 'imask';
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind('.js-keycard', function(baseElement) {
        IMask(baseElement, {
            mask: [
                {
                    mask: '00-0000 0000 0000 0000 0000-0',
                    maxLength: 29,
                    lazy: true,
                },
                {
                    mask: '#000000',
                    placeholderChar: ' ',
                    maxLength: 7,
                    lazy: false,
                    prepare: function (str) {
                        return str.toUpperCase();
                    },
                    definitions: {
                        '#': /[A,a,b,B,c,C,d,D,e,E,f,F,u,U]/
                    }
                },
            ]
        });
    })
}


