import {onFind} from "@elements/init-modules-in-scope";
import {on} from "@elements/dom-utils";

export function init() {
    onFind('.js-history-back', function (button) {
        console.log("clicked");
        on('click', function(e) {
            e.preventDefault();
            history.back(1);
        }, button);
    });
}
