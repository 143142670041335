"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {findAll,findIn, closest, removeClass, findAllIn, on, removeAttribute, addAttribute} from '@elements/dom-utils';


const selectors = {
    base: '.js-qr-scanner',
    btn: '.js-qr-scanner__btn',
    result: '.js-qr-scanner__result-input',
    video: '.js-qr-scanner__video',
    preview: '.js-qr-scanner__preview',
    close: '.js-qr-scanner__close',
    error: '.js-qr-scanner__error'
};

export function init() {
    onFind(selectors.btn, function (button) {
        import('qr-scanner').then((module) => {
            const QrScanner = module.default;
            QrScanner.WORKER_PATH = '/static/js/libs/qr-scanner-worker.min.js';
            try {
                QrScanner.hasCamera().then(function (deviceHasCamera) {

                    if (deviceHasCamera) {
                             let base = button.closest(selectors.base);
                        try {


                            let video = findIn(selectors.video, base);

                            let resultInput = findIn(selectors.result, base);
                            let preview = findIn(selectors.preview, base);
                            let close = findIn(selectors.close, base);
                            let scanner;
                            on('click', function (evt) {
                                evt.preventDefault();
                                scanner = new QrScanner(video, result => setQrScanResult(resultInput, preview, result, scanner, base),{
                                    onDecodeError: error => {
                                        resultInput.textContent = error;
                                        resultInput.style.color = 'inherit';
                                    },
                                    highlightScanRegion: true,
                                    highlightCodeOutline: true,
                                });
                                video.play();
                                scanner.start();
                                removeAttribute('hidden', preview);

                            }, button);
                            on('click', function (evt) {
                                evt.preventDefault();
                                preview.setAttribute('hidden', 'hidden');
                                scanner.stop();
                                scanner.destroy();
                                scanner = null;
                            }, close);
                        } catch (error) {
                            button.setAttribute('hidden', 'hidden');
                            //let errorContainer = findIn(selectors.error, base);
                            //errorContainer.setAttribute('hidden', false);
                        }

                    } else {
                        button.setAttribute('hidden', 'hidden');
                        console.warn('QR-Scanner: No Camera detected. QR Scanner will not be available for voucher scanning.');
                    }
                });
            } catch {
                console.warn("something went wrong....");
            }

        });
        function setQrScanResult(resultInput, preview, resultValue, scanner, base) {
            try {
                let voucherValue = (new URL(resultValue.data)).searchParams.get('rfid');
                if (voucherValue) {
                    resultInput.value =  voucherValue;
                    resultInput.dispatchEvent(new Event('input', { bubbles: true }));
                }
            } catch (error) {
                let errors = findIn(selectors.error, base);
                removeAttribute('hidden', errors);
            }

            preview.setAttribute('hidden', 'hidden');
            scanner.stop();
            scanner.destroy();
            scanner = null;
        }
    })

}
