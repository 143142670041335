import './scripts/common';
import {onFind} from "@elements/init-modules-in-scope";

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:
import * as ajaxForm from '@elements/ajax-form';
ajaxForm.init({submitOnChange: matchMedia('(max-width: 767px)').matches, addUrlParams: true});

import * as ajaxFormScrollTop from './scripts/ajax-form-scroll-top';
ajaxFormScrollTop.init();

import {find} from "@elements/dom-utils";
import * as datepicker from "@elements/datepicker";

onFind('.js-booking-datepicker',function (element) {
    datepicker.createDatepicker(element,{
        /*options*/
        mode: "range",
        minDate: "today",
        showMonths: 1,
        conjunction: " - "
    },{
        base: '.js-booking-datepicker',
        input: '.js-booking-datepicker__input',
        altField: '.js-booking-datepicker__alt-field',
        altFieldTo: '.js-booking-datepicker__alt-field-to'
    });
});


import Collapse from 'bootstrap/js/dist/collapse';
onFind('[data-bs-toggle="collapse"]',function (element) {
    new Collapse(element);
});

import * as sidenavAnimations from './scripts/sidenav-animations'
sidenavAnimations.init()

import * as warning from './scripts/warning'
warning.init();

import * as historyBack from './scripts/history-back';
historyBack.init();

import * as b2bCheckout from './scripts/b2b-checkout';
b2bCheckout.init();

import * as keycard from './scripts/keycard';
keycard.init();

import * as keycardTypeahead from './scripts/keycard-typahead';
keycardTypeahead.init();

import * as qrScanner from './scripts/qr-scanner';
qrScanner.init();