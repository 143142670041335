import { onFind } from '@elements/init-modules-in-scope'
import { hasClass, on, findAllIn, addClass, removeClass } from '@elements/dom-utils'
import { doAsynchronously } from './microinteraction-helpers'

const selectors = {
    list: '.js-sidenav-interactions-list',
    item: '.js-sidenav-interactions-item',
    link: '.js-sidenav-interactions-link'
}

export function init() {
    let activeItem = undefined
    const baseAnimationDuration = parseFloat(getCSSProperty('--js-sidenav-animations-duration')) * 1000

    on('readystatechange', () => document.readyState == 'complete' && doAnimations(), document)

    function doAnimations() {
        onFind(selectors.list, (list) => {
            findAllIn(selectors.item, list).forEach((item) => {
                handlePageVisitLink(item)
            })

            findAllIn(selectors.link, list).forEach((link) => {
                handlePageLeaveLink(link)
            })
        })

        function handlePageVisitLink(item) {
            if(hasClass('is-active', item)) {
                doAsynchronously(() => {
                    addClass('js-transition-border', item)
                    activeItem = item
                })
            }
        }

        function handlePageLeaveLink(link) {
            on("click", (e) => {
                e.preventDefault()
                hasClass('is-active', activeItem) && removeClass('is-active', activeItem)

                setTimeout(() => {
                    window.location.href = e.target.href
                }, baseAnimationDuration > 200 ? baseAnimationDuration - 100 : baseAnimationDuration) // starting the page switch a little earlier than when the animation ends, the whole thing feels smoother/faster
            }, link)
        }
    }

    function getCSSProperty(propertyName) {
        return getComputedStyle(document.documentElement).getPropertyValue(propertyName);
    }
}