import {on, findAllIn, findIn, addClass, removeClass, toggleClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";


const selectors = {
    btn: '.js-warning__close',
    wrapper: '.js-warnings__toast',
    toast: '.js-warning-toast',
};

export function init() {
    onFind(selectors.wrapper, function (wrapper) {
        let warningKeys = Object.keys(sessionStorage);
        let warnings = findAllIn(selectors.toast, wrapper);
        warnings.map((item) => {
            if (warningKeys.includes('warning-' + item.getAttribute('data-toast-id'))) {
                let warningObject = JSON.parse(sessionStorage.getItem('warning-' + item.getAttribute('data-toast-id')));
                if (warningObject.closed) {
                    item.remove()
                }
            }
            removeClass('warnings__toast--hide', wrapper);
            let btn = findIn(selectors.btn, item);
            on('click', function () {

                onFind(selectors.toast, function () {
                    addClass('body--has-warnings', document.body);
                });

                toggleClass('warning__show-toast', item);
                addClass('warning__hide-toast', item);

                let warnings = findAllIn('.warning__show-toast', wrapper);

                if (!warnings.length > 0) {
                    console.log('remove');
                    removeClass('body--has-warnings', document.body);
                }

                let time = new Date().toLocaleString();
                let id = item.getAttribute('data-toast-id');
                let warningObject = {'time': time, 'id': id, 'closed': true};
                sessionStorage.setItem('warning-' + id, JSON.stringify(warningObject));
            }, btn);
        });
    });

    onFind(selectors.toast, function () {
        addClass('body--has-warnings', document.body);
    });

}


