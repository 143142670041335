import {onFind} from "@elements/init-modules-in-scope";
import {on, findIn, trigger} from "@elements/dom-utils";

export function init() {
    onFind('.js-ajax-form', function (element) {
        on('fetched.ajax-form', (evt) => successHandler( evt.detail, evt.target), element);

        const successHandler = (params, element) => {
            let scrollToTopAttr = element.getAttribute('data-ajax-form-scroll-to-top');

            // scroll to beginning of ajax-form when the attribte 'data-ajax-form-scroll-top-top' is set
            if(scrollToTopAttr === 'true'){
                let bodyRect = document.body.getBoundingClientRect().top;
                let elementRect = element.getBoundingClientRect().top;
                let elementPosition = elementRect - bodyRect;
                let offsetPosition = elementPosition - 150;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            }
        };
    });
}
